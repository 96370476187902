<template>

	<div class="main-contents">
        <div class="mem_container">
            <div class="mem_info_contents">
                <div class="mem_contents_header">
                    <div class="title-txt">My Page<br/>
                        <p><span class="prj_ct">[프로젝트담당]<nbsp n="2"/><span>{{data.project.mberNm}}</span> 님 반갑습니다!</span></p><p class="pf_service"><span>[ <SelectComp type="text" list="N:무료,Y:유료" v-model="data.project.contractYn"/> 서비스 회원 ]</span> 이십니다!</p>
                    </div>
                </div>
                <div class="contents-body tab point dco">
                    <div class="my-resume">
                        <div class="photo">
                            <div class="img"><ImageComp type="image" :src="data.imgSrc" defaultSrc="COMPANY"/></div>
                            <span class="ctm">{{data.project.corpNm}}</span>
                            <!-- <div class="prj_enroll" @click="$router.push('PRJ301M01')">
                                <img class="prj_enroll" src="/images/prj_enroll.png" alt="프로젝트등록"/>
                            </div> -->
                            <div class="prj_enroll"></div>
                            <div class="intesrt_mem" @click="$router.push('PRF301M01')">관심인재 등록 : <span>{{data.project.interestCnt | number}}</span> &#9654; </div>
                            <div class="info_change" @click="$router.push('MYP302M01')">
                                <img class="info_change" src="/images/info_change.png" alt="정보수정"/>
                            </div>
                        </div>
                        <div class="mem-box">
                            <div class="tit">공급기업 지정 프로젝트</div>
                            <div class="mem-count">
                                <div class="mem">프로젝트 합격인재</div>
                                <div class="count">{{data.scoProject.projectApplyCnt | number}}명</div>
                                <span class="noti" v-if="data.scoProject.projectApplyCnt > 0">!</span>
                                <div class="shortcut" @click="$router.push('PRJ307M01')">바로가기 &#9654;</div>
                            </div>
                            <div class="mem-count">
                                <div class="mem">제안단계 프로젝트</div>
                                <div class="count">{{data.scoProject.projectProCnt | number}}건</div>
                                <div class="shortcut" @click="$router.push('PRJ307M02')">바로가기 &#9654;</div>
                            </div>
                            <div class="mem-count">
                                <div class="mem">수행예정 프로젝트</div>
                                <div class="count">{{data.scoProject.projectBeforeCnt | number}}건</div>
                                <div class="shortcut" @click="$router.push('PRJ307M03')">바로가기 &#9654;</div>
                            </div>
                            <div class="mem-count">
                                <div class="mem">수행중인 프로젝트</div>
                                <div class="count">{{data.scoProject.projectNowCnt | number}}건</div>
                                <div class="shortcut" @click="$router.push('PRJ307M04')">바로가기 &#9654;</div>
                            </div>
                            <div class="mem-count">
                                <div class="mem">완료 프로젝트</div>
                                <div class="count">{{data.scoProject.projectEndCnt | number}}건</div>
                                <div class="shortcut" @click="$router.push('PRJ307M05')">바로가기 &#9654;</div>
                            </div>
                            <div class="mem-count">
                                <div class="mem">미평가인재</div>
                                <div class="count">{{data.scoProject.evalNeedCnt | number}}명</div>
                                <span class="noti" v-if="data.scoProject.evalNeedCnt > 0">!</span>
                                <div class="shortcut" @click="$router.push('PRJ307M05')">바로가기 &#9654;</div>
                            </div>
                        </div>
                        <div class="mem-box last">
                            <div class="tit">일반 프로젝트</div>
                            <div class="mem-count">
                                <div class="mem">프로젝트 지원인재</div>
                                <div class="count">{{data.project.projectApplyCnt | number}}명</div>
                                <span class="noti" v-if="data.project.projectApplyCnt > 0">!</span>
                                <div class="shortcut" @click="$router.push('PRJ305M01')">바로가기 &#9654;</div>
                            </div>
                            <div class="mem-count">
                                <div class="mem">제안단계 프로젝트</div>
                                <div class="count">{{data.project.projectProCnt | number}}건</div>
                                <div class="shortcut" @click="$router.push('PRJ302M05')">바로가기 &#9654;</div>
                            </div>
                            <div class="mem-count">
                                <div class="mem">수행예정 프로젝트</div>
                                <div class="count">{{data.project.projectBeforeCnt | number}}건</div>
                                <div class="shortcut" @click="$router.push('PRJ302M02')">바로가기 &#9654;</div>
                            </div>
                            <div class="mem-count">
                                <div class="mem">수행중인 프로젝트</div>
                                <div class="count">{{data.project.projectNowCnt | number}}건</div>
                                <div class="shortcut" @click="$router.push('PRJ302M01')">바로가기 &#9654;</div>
                            </div>
                            <div class="mem-count">
                                <div class="mem">완료 프로젝트</div>
                                <div class="count">{{data.project.projectEndCnt | number}}건</div>
                                <div class="shortcut" @click="$router.push('PRJ302M03')">바로가기 &#9654;</div>
                            </div>
                            <div class="mem-count">
                                <div class="mem">미평가인재</div>
                                <div class="count">{{data.project.evalNeedCnt | number}}명</div>
                                <span class="noti" v-if="data.project.evalNeedCnt > 0">!</span>
                                <div class="shortcut" @click="$router.push('PRJ302M03')">바로가기 &#9654;</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 프로젝트 인터뷰 요청 상태 -->
                <div class="contents-body prj dco">
                    <div class="title"><img src="/images/interview.png" alt="프로젝트인터뷰요청상태"/>일반 프로젝트 인터뷰 요청 상태</div>
                    <!-- 1set -->
                    <template v-if="list && list.length > 0">
                        <template v-for="(item, idx) in list">
                            <hr v-if="idx != 0" :key="'hr_' + idx"/>
                            <div class="prj-box" :key="'project_' + idx">{{item.projectNm}}</div>
                            <div class="member" :key="'total_' + idx">
                                <p>인터뷰 요청 인재</p>
                                <span class="count">총 {{item.interviewTotalCnt | number}}명</span>
                            </div>
                            <div class="member" :key="'detail_' + idx">
                                <p>인터뷰 예정</p>
                                <span class="count">{{item.interview01Cnt + item.interview02Cnt | number}}명</span>
                                / 
                                <p>인터뷰 합격</p>
                                <span class="count">{{item.interview04Cnt | number}}명</span>
                                / 
                                <p>인터뷰 불합격</p>
                                <span class="count">{{item.interview03Cnt | number}}명</span>
                                <div class="shortcut" @click="movePage(item)">바로가기 &#9654;</div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="prj-box">인터뷰 중인 프로젝트가 없습니다.</div>
                    </template>
                </div>
                <!-- 마이페이지 하단 배너 부분 -->
                <div class="mypage-banner">
                    <div class="tit">하이프로만의 특별함!</div>
					<div class="banner">
						<img src="/images/mypage_bn01.png" alt="마이페이지배너1"/>
						<p @click="$router.push({name:'MAN001M01', query:{'target':'infoPoint'}})">자세히 보기 &#9654;</p>
					</div>
					<div class="banner">
						<img src="/images/mypage_bn02.png" alt="마이페이지배너2"/>
						<p @click="$router.push({name:'MAN001M01', query:{'target':'infoSafety'}})">자세히 보기 &#9654;</p>
					</div>
					<div class="banner">
						<img src="/images/mypage_bn03.png" alt="마이페이지배너3"/>
						<p @click="$router.push({name:'MAN001M01', query:{'target':'infoAS'}})">자세히 보기 &#9654;</p>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

	data() {
		return {
			data: {},
            list: [],
		};
	},
	mounted(){
		this.$.httpPost('/api/mem/myp/getDcoPmgPage')
			.then(res => {
                //console.log(res.data);
				this.data = res.data;
				this.data.imgSrc = '/api/mem/myp/corpImageDown?corpSeq=' + this.data.project.corpSeq;
			});
		this.$.httpPost('/api/prj/prj/getInterviewProject')
			.then(res => {
				this.list = res.data.list;
                //console.log(this.list)
			});
	},
	methods: {
        movePage(item) {
            var params = {

				projectSeq : item.projectSeq,
				projectNm : item.projectNm,

				caller : {
					name: this.$route.name,
				}
			}
			this.$router.push({name: 'PRJ306M02', params });
        }
	},
};
</script>